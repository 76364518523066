import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

document.addEventListener('DOMContentLoaded', () => {
	new Swiper('.swiper.quotes', {
		modules: [Navigation, Pagination, Autoplay],
		slidesPerView: 'auto',
		slidesOffsetBefore: 64,
		spaceBetween: 32,
		loop: true,
		speed: 1000,
		grabCursor: true,
		createElements: true,

		pagination: {
			enabled: true,
			clickable: true,
		},
		navigation: true,

		autoplay: {
			delay: 8000,
			pauseOnMouseEnter: true,
		},
	});
});
